import React from "react"
import { useTheme } from "emotion-theming"
import Layout from "../../../components/Layout"
import "../../../styles/global.css"
import { Section } from "../../../components/PageComponents/Section"
import SEO from "../../../components/seo"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const ReactDeveloperPage = () => {
  const theme = useTheme()

  return (
    <Layout>
      <SEO title="evoli - evoli söker en React utvecklare" noIndex={true} />
      <Section>
        <ContentContainer>
          <h3>evoli söker en senior React utvecklare</h3>
          <p>
            evoli är ett företag med fokus på insurtech som utmanar den svenska
            försäkringsmarknaden med prisvärda försäkringar till en noga utvald
            målgrupp. Bland grundarna så finns både erfarna entreprenörer och
            människor med specialistkunskap inom försäkring. Hos evoli är
            arbetsdagen händelserik och vi är ett tight team som jobbar
            målmedvetet tillsammans och har roligt ihop.
            <br />
            <br />
            Vi söker nu en React utvecklare som kan hjälpa vårt IT team med
            utveckling av vår plattform. Vi har ett agilt arbetssätt och
            kombinerar arbete remote med dagar tillsammans på vårt kontor i
            centrala Stockholm.
          </p>
          <strong>Efterfrågad erfarenhet</strong>
          <ul>
            <li>Minst 3 års erfarenhet av Javascript, React, HTML, CSS.</li>
            <li>
              Erfarenhet av Apollo, Redux, Formik, GraphQL/Hasura,
              emotion/styled components och react-query.
            </li>
          </ul>

          <p>
            Det är meriterande om du också har erfarenhet av next.JS, Gatsby,
            MaterialUI, TailwindCSS. Utöver detta ser vi att du kommunicerar
            flytande på svenska och engelska och trivs att arbeta i ett team där
            vi delar kunskap och idéer med varandra.
            <br />
            <br />
            Skicka din ansökan så snart som möjligt till head of PMO -{" "}
            <a href="mailto:petronella@evoli.se">Petronella@evoli.se</a>. Vi
            behandlar ansökningar kontinuerligt eftersom vi vill tillsätta
            tjänsten snabbt.
          </p>
        </ContentContainer>
      </Section>
    </Layout>
  )
}

export default ReactDeveloperPage

const ContentContainer = styled.section`
  color: #292944;
  font-size: 14px;
  max-width: 600px;

  ul {
    margin-top: 5px;
  }
  li {
    margin-bottom: 2px;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 10px;
  }
  h3 {
    font-weight: 500;
  }

  strong {
    font-weight: 500;
  }
  a {
    color: #5558ea;
    font-weight: 500;
    :hover {
      text-decoration: underline;
    }
  }
`
